import { TIMELINE_ARRAYS } from "@/constant/timelineConstant";
import { timelineAtom } from "@/store/timeline/atom";
import type { Timeline } from "@/types/timeline/types";
import type { ApexOptions } from "apexcharts";
import { useSetAtom } from "jotai";
import ReactApexChart from "react-apexcharts";
import { useState } from "react";
import {
  getAnnotationStyle,
  getBaseChartOptions,
} from "@/constant/LINE_CHART_OPTION";

const LineChart = ({ data }: { data: Timeline[] }) => {
  const { chartSeries, resultDateArray } = TIMELINE_ARRAYS(data);
  const setSelectedTimeline = useSetAtom(timelineAtom);
  const [, setSelectedDate] = useState<string | null>(null);
  const [annotation, setAnnotation] = useState<ApexOptions["annotations"]>({});

  const baseOptions = getBaseChartOptions(resultDateArray);

  const chartOptions: ApexOptions = {
    ...baseOptions,
    series: chartSeries,
    annotations: annotation,
    chart: {
      ...baseOptions.chart,
      events: {
        click(_event, _chartContext, config) {
          const { dataPointIndex } = config;
          const selectedTimeline = data[dataPointIndex];
          const clickedDate = resultDateArray[dataPointIndex];

          setSelectedDate(clickedDate);
          setSelectedTimeline(selectedTimeline);
          setAnnotation(getAnnotationStyle(clickedDate));
        },
      },
    },
  };

  return (
    <div>
      <ReactApexChart
        options={chartOptions}
        series={chartSeries}
        type="line"
        height={350}
      />
    </div>
  );
};

export default LineChart;
