import type { InspectionDashboardResponse } from "@/types/inspection/types";

const EmptyRows = ({ content }: InspectionDashboardResponse) => {
  const rowCount = 10;
  const emptyRows = rowCount - (content?.length || 0);

  return (
    <>
      {Array.from({ length: emptyRows }).map((_, index) => (
        <tr key={`empty-row-${index}`}>
          <td className="py-5 swhitespace-nowrap text-sm text-center font-medium text-gray-900"></td>
          <td className="py-5 whitespace-nowrap text-sm text-center text-gray-500"></td>
          <td className="py-5 whitespace-nowrap text-sm text-center font-medium text-gray-900"></td>
          <td className="py-5 whitespace-nowrap text-sm text-center text-gray-500"></td>
          <td className="py-5 whitespace-nowrap text-sm text-center text-gray-500"></td>
        </tr>
      ))}
    </>
  );
};

export default EmptyRows;
