import type { Timeline } from "@/types/timeline/types";
import { apiClient, apiProgress } from "../common/api";

export const getInspectionTimeline = async ({
  token,
  inspectionId,
  onProgress,
}: {
  token: string;
  inspectionId: number;
  onProgress?: (arg: number) => void;
}) => {
  try {
    const { data } = await apiClient.get<Timeline[]>(
      `/inspection/timeline/${inspectionId}`,
      {
        onDownloadProgress(progressEvent) {
          if (onProgress) apiProgress(progressEvent, onProgress);
        },
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    return data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
