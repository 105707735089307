const Navigation = () => {
  return (
    <nav className="bg-white border-b border-gray-200">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center h-16">
          <div className="flex space-x-8">
            <a href="/" className="text-blue-400 font-bold">
              Logo
            </a>
            <a href="/features" className="text-gray-600 hover:text-blue-400">
              Features
            </a>
            <a href="/about" className="text-gray-600 hover:text-blue-400">
              About
            </a>
          </div>
          <div className="flex items-center space-x-4">
            <a href="/login" className="text-gray-600 hover:text-blue-400">
              Login
            </a>
            <a
              href="/test/signup"
              className="bg-blue-400 text-white px-4 py-2 rounded hover:bg-blue-500"
            >
              Sign Up
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
