import React from "react";

interface PredictionResultProps {
  normal: number;
  benign: number;
  malignant: number;
}

const PredictionResult: React.FC<PredictionResultProps> = ({
  normal,
  benign,
  malignant,
}) => {
  return (
    <div className="space-y-4 p-2">
      <h1 className="text-xl font-semibold text-gray-900">검사 결과</h1>
      <div className="w-full space-y-2">
        {/* Progress bar container */}
        <div className="h-12 w-full bg-gray-100 rounded-lg overflow-hidden relative">
          {/* Progress segments */}
          <div
            className="absolute top-0 left-0 h-full bg-green-500 rounded-l-lg transition-all duration-500 ease-out"
            style={{ width: `${normal}%` }}
          >
            {normal >= 15 && (
              <span className="absolute inset-0 flex items-center justify-center text-white font-bold">
                {normal}%
              </span>
            )}
          </div>
          <div
            className="absolute top-0 h-full bg-orange-500 transition-all duration-500 ease-out"
            style={{ left: `${normal}%`, width: `${benign}%` }}
          >
            {benign >= 15 && (
              <span className="absolute inset-0 flex items-center justify-center text-white font-bold">
                {benign}%
              </span>
            )}
          </div>
          <div
            className="absolute top-0 h-full bg-red-500 rounded-r-lg transition-all duration-500 ease-out"
            style={{ left: `${normal + benign}%`, width: `${malignant}%` }}
          >
            {malignant >= 15 && (
              <span className="absolute inset-0 flex items-center justify-center text-white font-bold">
                {malignant}%
              </span>
            )}
          </div>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4 mt-4">
        <div className="bg-green-50 p-4 rounded-lg">
          <div className="flex items-center gap-2 mb-2">
            <div className="w-4 h-4 rounded-full bg-green-500"></div>
            <span className="font-semibold">정상</span>
          </div>
          <div className="text-xl font-bold mb-1">{normal}%</div>
          <div className="text-sm text-gray-600">정상 범위 내 결과</div>
        </div>

        <div className="bg-orange-50 p-4 rounded-lg">
          <div className="flex items-center gap-2 mb-2">
            <div className="w-4 h-4 rounded-full bg-orange-500"></div>
            <span className="font-semibold">양성 종양</span>
          </div>
          <div className="text-xl font-bold mb-1">{benign}%</div>
          <div className="text-sm text-gray-600">추가 검사 필요</div>
        </div>

        <div className="bg-red-50 p-4 rounded-lg">
          <div className="flex items-center gap-2 mb-2">
            <div className="w-4 h-4 rounded-full bg-red-500"></div>
            <span className="font-semibold">악성 종양</span>
          </div>
          <div className="text-xl font-bold mb-1">{malignant}%</div>
          <div className="text-sm text-gray-600">즉각적인 조치 필요</div>
        </div>
      </div>
    </div>
  );
};

export default PredictionResult;
