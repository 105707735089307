import React from "react";
import type {
  FieldError,
  FieldValues,
  Path,
  RegisterOptions as RHFRegisterOptions,
  UseFormRegister,
} from "react-hook-form";
interface Props<T extends FieldValues> extends React.ComponentProps<"input"> {
  label: string;
  id: Path<T>;
  type: string;
  placeholder?: string;
  register: UseFormRegister<T>;
  registerOptions: RHFRegisterOptions<T, Path<T>>;
  errors?: FieldError;
  isRequired: boolean;
  disabled?: boolean;
}

const AuthInput = <T extends FieldValues>({
  label,
  id,
  register,
  errors,
  placeholder,
  isRequired,
  registerOptions,
  type,
  disabled,
  ...props
}: Props<T>) => {
  const autocompleteValue =
    id === "password"
      ? "new-password"
      : id === "confirmPassword"
      ? "current-password"
      : undefined;
  return (
    <div>
      <div className="mb-6">
        <div className="flex items-center">
          {isRequired && <p className="text-red-400 mr-1">*</p>}
          <label
            htmlFor={id}
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            {label}
          </label>
        </div>
        <input
          type={type}
          id={id}
          placeholder={placeholder}
          className="w-full border p-2 rounded-lg"
          {...register(id, { ...registerOptions })}
          {...props}
          autoComplete={autocompleteValue}
          disabled={disabled}
        />
        {errors && (
          <span className="text-red-500 text-sm">{errors.message}</span>
        )}
      </div>
    </div>
  );
};

export default AuthInput;
