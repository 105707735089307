import { useState } from "react";
import { motion, type Variants } from "framer-motion";
import { useAtom } from "jotai";
import { inspectionOptionAtom } from "@/store/inspection/atom";

const SelectBox = ({
  setCurrentPage,
}: {
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [inspectionOption, setInspectionOption] = useAtom(inspectionOptionAtom);
  const [isOpen, setIsOpen] = useState(false);

  const itemVariants: Variants = {
    open: {
      opacity: 1,
      y: 0,
      transition: { type: "spring", stiffness: 300, damping: 24 },
    },
    closed: { opacity: 0, y: -20, transition: { duration: 0.2 } },
  };

  const handleSelect = (option: string) => {
    if (option === "전체 검사") {
      setInspectionOption("");
      setCurrentPage(0);
    }
    setCurrentPage(0);
    setInspectionOption(option);
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block text-left w-[120px]">
      <motion.button
        aria-label="셀렉트박스 메뉴열기"
        whileTap={{ scale: 0.97 }}
        onClick={() => setIsOpen(!isOpen)}
        className="inline-flex justify-between items-center w-full bg-white border border-gray-300 rounded-md shadow-sm px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
      >
        {inspectionOption}
        <motion.div
          variants={{
            open: { rotate: 180 },
            closed: { rotate: 0 },
          }}
          transition={{ duration: 0.2 }}
          style={{ originY: 0.55 }}
          className=""
        >
          <svg width="15" height="15" viewBox="0 0 20 20">
            <path d="M0 7 L 20 7 L 10 16" />
          </svg>
        </motion.div>
      </motion.button>

      <motion.ul
        initial="closed"
        animate={isOpen ? "open" : "closed"}
        variants={{
          open: {
            clipPath: "inset(0% 0% 0% 0% round 10px)",
            transition: {
              type: "spring",
              bounce: 0,
              duration: 0.7,
              delayChildren: 0.1,
              staggerChildren: 0.05,
            },
          },
          closed: {
            clipPath: "inset(10% 50% 90% 50% round 10px)",
            transition: {
              type: "spring",
              bounce: 0,
              duration: 0.3,
            },
          },
        }}
        className="absolute mt-2 w-full bg-white border border-gray-300 rounded-md shadow-lg overflow-hidden z-10"
        style={{ pointerEvents: isOpen ? "auto" : "none" }}
      >
        {["전체 검사", "검사 진행중", "검사 완료"].map((option: string) => (
          <motion.li
            key={option}
            variants={itemVariants}
            onClick={() => handleSelect(option)}
            className="cursor-pointer px-4 py-2 text-gray-900 hover:bg-gray-100"
          >
            {option}
          </motion.li>
        ))}
      </motion.ul>
    </div>
  );
};

export default SelectBox;
