import { authenticateUser } from "@/api/login/api";
import { getUserInfo } from "@/api/myinfo/api";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
// 앞서 만든 useStorage 훅 import
import type { LoginDTO } from "../../types/login/types";
import { useStorage } from "./useStorage";

interface UseAuth {
  type: "accessToken" | "admin_accessToken";
}

const useAuth = ({ type }: UseAuth) => {
  const navigate = useNavigate();
  const { setAccessToken, setAdminToken, setUsername, setAdminName } =
    useStorage();

  return useMutation({
    mutationFn: async (data: LoginDTO) => {
      const auth = await authenticateUser(data);
      if (!auth?.headers["authorization"]) {
        throw new Error("로그인 정보가 다릅니다.");
      }

      const userInfo = await getUserInfo(auth.headers["authorization"]);
      if (!userInfo?.name) {
        throw new Error("사용자 정보를 불러올 수 없습니다.");
      }

      return {
        accessToken: auth.headers["authorization"],
        username: userInfo.name,
      };
    },
    onSuccess: (data) => {
      if (type === "accessToken") {
        setAccessToken(data.accessToken);
        setUsername(data.username);
        navigate("/");
      } else {
        setAdminToken(data.accessToken);
        setAdminName(data.username);
        navigate("/admin/dashboard");
      }
    },
  });
};

export default useAuth;
