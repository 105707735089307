import type { LoginDTO } from "@/types/login/types";
import { apiClient } from "@/api/common/api";

export const authenticateUser = async (loginDTO: LoginDTO) => {
  try {
    const response = await apiClient.post("/login", loginDTO);
    return response;
  } catch (err) {
    console.log(err);
  }
};
