import MetaDxLogo from "@/assets/metadx-logo.svg?react";
import LinkedInLogo from "@/assets/linked-in-icon.svg?react";
import InstagramLogo from "@/assets/instagram-icon.svg?react";
import DownloadLogo from "@/assets/download-icon.svg?react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-white flex flex-col md:flex-row relative text-left text-sm px-[200px] py-3 before:content-[''] before:absolute before:inset-x-0 before:-top-1 before:h-2 md:mt-24 z-10">
      <div className="w-full md:w-1/2 mr-[40px] flex flex-col mb-8 md:mb-0">
        <MetaDxLogo className="w-[130px] h-[40px]" />
        <div className="flex mt-4">
          <strong className="mr-2">(주)메타디엑스</strong> 대표이사 김진욱
          <p className="ml-3">사업자등록번호 797-86-03070</p>
        </div>
        <p className="mt-1">
          서울특별시 광진구 광나루로 474 광진경제허브센터 키움관 405호
        </p>
        <p className="my-4"></p>
        <p className="text-gray-500">
          Copyright MetaDx Lab. All right reserved.
        </p>
      </div>

      <div className="w-full md:w-1/2 flex flex-col justify-end">
        <div className="flex items-center text-xl gap-4">
          <Link to="/introduce" className="cursor hover:text-red-200">
            회사소개
          </Link>{" "}
          |
          <Link to="/policy" className="cursor hover:text-red-200">
            {" "}
            개인정보처리방침
          </Link>
        </div>
        <div className="mt-4 flex items-center text-md">
          <span className="mr-4 font-bold">회사소개서</span>
          <div className="flex space-x-4">
            <button
              aria-label="국문 소개서 다운로드 버튼"
              className="flex items-center justify-center shadow-md p-2 hover:bg-gray-100 text-lg rounded"
            >
              <DownloadLogo className="w-7 h-7 mr-2" /> 국문
            </button>
            <button
              aria-label="영문 소개서 다운로드 버튼"
              className="flex items-center justify-center shadow-md p-2 hover:bg-gray-100 text-lg rounded"
            >
              <DownloadLogo className="w-7 h-7 mr-2" /> 영문
            </button>
          </div>
        </div>
        <div className="mt-4 flex items-center  text-md">
          <span className="mr-7 font-bold">회사링크</span>
          <div className="flex space-x-4">
            <Link
              to="https://kr.linkedin.com/company/metadxlab"
              className="flex items-center justify-center shadow-md p-2 hover:bg-gray-100 text-lg rounded"
            >
              <LinkedInLogo className="w-7 h-7 mr-2" />
              LinkedIn
            </Link>
            <Link
              to="https://www.instagram.com/metadx.vet"
              className="flex items-center justify-center shadow-md p-2 hover:bg-gray-100 text-lg rounded"
            >
              <InstagramLogo className="w-7 h-7 mr-2" />
              Instagram
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
