import { useQuery } from "@tanstack/react-query";
import type { InspectionDetail } from "@/types/inspection/types";
import { getInspectionDetail } from "@/api/inspection/api";

interface UseGetInspectionDetailProps {
  token: string;
  inspectionId?: number;
}

export const useGetInspectionDetail = ({
  token,
  inspectionId,
}: UseGetInspectionDetailProps) => {
  return useQuery<InspectionDetail>({
    queryKey: ["inspectionDetail", inspectionId],
    queryFn: () => getInspectionDetail({ token, inspectionId: inspectionId! }),
    enabled: !!inspectionId && !!token,
  });
};
