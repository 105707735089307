// hooks/usePagination.ts
import { useState } from "react";
import type { Dispatch, SetStateAction } from "react";

interface UsePaginationProps {
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  dataLength: number;
  pageGroupSize?: number;
}

export const usePagination = ({
  currentPage,
  setCurrentPage,
  dataLength,
  pageGroupSize = 10,
}: UsePaginationProps) => {
  const [inputPage, setInputPage] = useState("");
  const pageCount = Math.ceil(dataLength / 10);
  const startPage = Math.floor(currentPage / pageGroupSize) * pageGroupSize + 1;
  const endPage = Math.min(startPage + pageGroupSize - 1, pageCount);

  const handlePreviousGroup = () => {
    setCurrentPage((prev) => Math.max(prev - pageGroupSize, 0));
  };

  const handleNextGroup = () => {
    setCurrentPage((prev) => {
      const nextPage = prev + pageGroupSize;
      if (nextPage < pageCount) {
        return nextPage;
      }
      return pageCount - 1;
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setInputPage(value);
    }
  };

  const goToPage = () => {
    const page = parseInt(inputPage, 10);
    if (isNaN(page) || page < 1 || page > pageCount) {
      alert("올바른 숫자를 입력해주세요!");
    } else {
      setCurrentPage(page - 1);
      setInputPage("");
    }
  };

  const generatePageNumbers = () => {
    const pages: (string | number)[] = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };

  return {
    inputPage,
    pageCount,
    startPage,
    endPage,
    handlePreviousGroup,
    handleNextGroup,
    handleInputChange,
    goToPage,
    generatePageNumbers,
  };
};
