import DownloadIcon from "@/assets/download-icon.svg?react";

const DownloadButton = () => {
  return (
    <button>
      <DownloadIcon className="w-6 h-6 fill-gray-500 hover:fill-blue-400" />
    </button>
  );
};

export default DownloadButton;
