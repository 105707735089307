import type { InspectionDashboardResponse } from "@/types/inspection/types";
import EmptyRows from "@/components/inspection/dashboard/EmptyRows";
import type { Dispatch } from "react";

interface TestTableProps extends InspectionDashboardResponse {
  setSelectedId: Dispatch<React.SetStateAction<number | undefined>>;
  selectedId?: number;
}

const TestTable = ({ content, setSelectedId, selectedId }: TestTableProps) => {
  const DASHBOARD_HEADER = ["이름", "칩번호", "종", "성별", "검사의뢰일"];

  return (
    <>
      <table className="min-w-full h-[750px] mb-5 divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            {DASHBOARD_HEADER.map((header) => (
              <th
                key={header}
                className=" py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {content?.map((data) => (
            <tr
              key={data.inspectionNumber}
              className={`transition-colors duration-200 cursor-pointer
                ${selectedId === data.id ? "bg-blue-50" : "hover:bg-gray-50"}`}
              onClick={() => setSelectedId(data.id)}
            >
              <td className=" py-4 whitespace-nowrap text-sm text-center font-medium text-gray-900">
                <div className="flex items-center justify-center gap-2">
                  <div
                    className={`w-2 h-2 rounded-full ${
                      !data.resultDate ? "bg-yellow-400" : "bg-green-400"
                    }`}
                  />
                  <span>{data.name}</span>
                </div>
              </td>
              <td className=" py-4 whitespace-nowrap text-sm text-center font-medium text-gray-900">
                {data.inspectionNumber}
              </td>
              <td className=" py-4 whitespace-nowrap text-sm text-center text-gray-500">
                {data.race}
              </td>
              <td className=" py-4 whitespace-nowrap text-sm text-center text-gray-500">
                {data.sex}
              </td>
              <td className=" py-4 whitespace-nowrap text-sm text-center text-gray-500">
                {data.requestDate.split("T")[0]}
              </td>
            </tr>
          ))}
          <EmptyRows content={content} totalElements={content.length} />
        </tbody>
      </table>
    </>
  );
};

export default TestTable;
