import type { Dispatch, SetStateAction } from "react";
import ChevronLeft from "@/assets/chevron-left.svg?react";
import ChevronRight from "@/assets/chevron-right.svg?react";
import { usePagination } from "@/hooks/common/usePagination";

interface PaginationProps {
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  dataLength: number;
}

const Pagination = ({
  currentPage,
  setCurrentPage,
  dataLength,
}: PaginationProps) => {
  const {
    inputPage,
    pageCount,
    handlePreviousGroup,
    handleNextGroup,
    handleInputChange,
    goToPage,
    generatePageNumbers,
  } = usePagination({
    currentPage,
    setCurrentPage,
    dataLength,
  });

  return (
    <div className="flex items-center space-x-2">
      <button
        aria-label="맨 처음으로 가는 버튼"
        onClick={handlePreviousGroup}
        className="flex justify-center items-center w-8 h-8 border rounded-md hover:bg-blue-600 hover:text-white disabled:bg-gray-100 disabled:cursor-not-allowed"
        disabled={currentPage < 10}
      >
        <ChevronLeft className="w-5 h-5" />
      </button>

      {generatePageNumbers().map((page, index) => (
        <button
          aria-label={`${index} 페이지로`}
          key={index}
          onClick={() => setCurrentPage((page as number) - 1)}
          className={`px-3 py-1 mx-1 rounded-md ${
            currentPage === (page as number) - 1
              ? "bg-blue-600 text-white"
              : "text-gray-700 hover:bg-gray-100"
          }`}
        >
          {page}
        </button>
      ))}

      <button
        aria-label="맨 끝"
        onClick={handleNextGroup}
        className="flex justify-center items-center w-8 h-8 border rounded-md hover:bg-blue-600 hover:text-white disabled:bg-gray-100 disabled:cursor-not-allowed"
        disabled={currentPage >= pageCount - 1}
      >
        <ChevronRight className="w-5 h-5" />
      </button>

      <div className="flex items-center space-x-2">
        <input
          type="text"
          value={inputPage}
          onChange={handleInputChange}
          className="w-12 border px-2 py-1 rounded-md text-center mr-2"
          placeholder=""
        />
        /{pageCount}
        <button
          onClick={goToPage}
          className="px-3 py-1 bg-blue-600 text-white rounded-md"
        >
          이동
        </button>
      </div>
    </div>
  );
};

export default Pagination;
