import { useState } from "react";
import TestHeatMapChart from "./TestHeatMapChart";
import type { MetaboliteOnly } from "@/types/inspection/types";
import HelpIcon from "@/assets/help_icon.svg?react";
import ProgressBarChart from "@/components/detail/chart/ProgressBar";

const MetaboliteSimilarity = ({ chartInfo }: MetaboliteOnly) => {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <div className="p-2">
      <div className="flex items-center gap-2 relative">
        <div className="text-xl font-semibold text-gray-900">
          대사물질 유사도
        </div>
        <div
          className="relative"
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <HelpIcon className="w-5 h-5 fill-gray-400 cursor-help" />
          {showTooltip && (
            <div className="absolute z-50 left-6 top-0 w-[300px] p-6 text-sm bg-white border border-gray-200 rounded-lg shadow-lg">
              대사물질 평균값은 인공지능 모델 학습에 사용된 데이터로부터 정상,
              양성 종양, 악성 종양 별로 구분하여 계산된 값입니다. 의뢰하신
              검사의 혈액 내 대사물질 분포와 인공지능 모델 내 대사물질 분포의
              평균값의 유사도를 계산하여 히트맵 (Heatmap) 형식으로 제공합니다.
              유사도가 높은 대사물질은 빨간색, 유사도가 낮은 대사물질은
              파란색으로 표시됩니다. 유사도가 높은 대사물질이 더 많은 경우 해당
              카테고리에 속할 가능성이 더 높다고 판단할 수 있습니다.
            </div>
          )}
        </div>
      </div>
      <div className="flex mt-4 gap-10">
        <TestHeatMapChart chartInfo={chartInfo} />
        <ProgressBarChart />
      </div>
    </div>
  );
};

export default MetaboliteSimilarity;
