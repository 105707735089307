import { useGetInspectionDetail } from "@/hooks/inspection/useGetInspectionDetail";
import { useStorage } from "@/hooks/common/useStorage";
import PredictionResult from "./PredictionResult";
import DownloadButton from "./DownloadButton";
import ShareButton from "./ShareButton";
import MailButton from "./MailButton";
import MetaboliteSimilarity from "./MetaboliteSimilarity";

interface TestDetailProps {
  selectedId?: number;
}

const TestDetail = ({ selectedId }: TestDetailProps) => {
  const { accessToken } = useStorage();
  const { data, isLoading } = useGetInspectionDetail({
    token: accessToken as string,
    inspectionId: selectedId,
  });

  if (!selectedId) {
    return (
      <div className="bg-white shadow rounded-lg min-h-screen ml-5 p-6">
        <h2 className="text-xl font-semibold text-gray-900 mb-4">상세내용</h2>
        <p className="text-gray-500 text-center mt-10">
          검사 결과를 선택해주세요.
        </p>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="bg-white shadow rounded-lg  min-h-screen ml-5 p-6 flex justify-center items-center">
        로딩중입니다..
      </div>
    );
  }

  return (
    <div className="">
      <div className="flex items-center justify-between mb-4">
        <div className="text-xl font-semibold text-gray-900 ">기본 정보</div>
        <div className="flex gap-3">
          <DownloadButton />
          <ShareButton />
          <MailButton />
        </div>
      </div>
      <div className="space-y-6">
        <div className="bg-gray-50 rounded-lg p-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-sm text-gray-500">이름</p>
              <p className="font-medium">{data?.name}</p>
            </div>
            <div>
              <p className="text-sm text-gray-500">검사 번호</p>
              <p className="font-medium">{data?.inspectionNumber}</p>
            </div>
            <div>
              <p className="text-sm text-gray-500">견종</p>
              <p className="font-medium">{data?.race}</p>
            </div>
            <div>
              <p className="text-sm text-gray-500">성별</p>
              <p className="font-medium">{data?.sex}</p>
            </div>
            <div>
              <p className="text-sm text-gray-500">의심 부위</p>
              <p className="font-medium">
                <p className="font-medium">
                  {!data?.suspectedLocation ? "없음" : data.suspectedLocation}
                </p>
              </p>
            </div>
            <div>
              <p className="text-sm text-gray-500">몸무게</p>
              <p className="font-medium">{data?.weight}kg</p>
            </div>
          </div>
        </div>

        {data && (
          <PredictionResult
            malignant={Number(data.malignant)}
            normal={Number(data.normal)}
            benign={Number(data.benign)}
          />
        )}
        {data?.chartInfo && (
          <MetaboliteSimilarity chartInfo={data?.chartInfo} />
        )}
      </div>
    </div>
  );
};

export default TestDetail;
