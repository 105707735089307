import { HEATMAP_OPTION } from "@/constant/HEATMAP_CONSTANTS";
import useHeatmapChart from "@/hooks/timeline/useHeatmapChart";
import type { MetaboliteOnly } from "@/types/inspection/types";
import ReactApexChart from "react-apexcharts";

const TestHeatMapChart = ({ chartInfo }: MetaboliteOnly) => {
  const { heatmapSeries } = useHeatmapChart({ chartInfo });
  return (
    <ReactApexChart
      options={HEATMAP_OPTION}
      series={heatmapSeries}
      type="heatmap"
      height={650}
      width={350}
    />
  );
};

export default TestHeatMapChart;
