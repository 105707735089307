import { METABOLITE_NAMES } from "@/constant/HEATMAP_CONSTANTS";
import type { MetaboliteOnly } from "@/types/inspection/types";
import {
  calculateSimilarity,
  parseMetaboliteToArray,
} from "@/util/timeline/util";
import { useCallback, useMemo } from "react";

const useHeatmapChart = ({ chartInfo }: MetaboliteOnly) => {
  const metaboliteData = useMemo(
    () => ({
      Patient: parseMetaboliteToArray(chartInfo.Patient),
      Benign: parseMetaboliteToArray(chartInfo.Benign),
      Malignant: parseMetaboliteToArray(chartInfo.Malignant),
      Normal: parseMetaboliteToArray(chartInfo.Normal),
    }),
    [chartInfo]
  );

  const createSeriesData = useCallback(
    (metaboliteIndex: number) => {
      return [
        {
          x: "정상",
          y: Number(
            calculateSimilarity(
              metaboliteData.Patient[metaboliteIndex],
              metaboliteData.Normal[metaboliteIndex]
            ).toFixed(1)
          ),
        },
        {
          x: "양성",
          y: Number(
            calculateSimilarity(
              metaboliteData.Patient[metaboliteIndex],
              metaboliteData.Benign[metaboliteIndex]
            ).toFixed(1)
          ),
        },
        {
          x: "악성",
          y: Number(
            calculateSimilarity(
              metaboliteData.Patient[metaboliteIndex],
              metaboliteData.Malignant[metaboliteIndex]
            ).toFixed(1)
          ),
        },
      ];
    },
    [metaboliteData]
  );

  const heatmapSeries = useMemo(
    () =>
      METABOLITE_NAMES.map((name, index) => ({
        name,
        data: createSeriesData(index),
      })),
    [createSeriesData]
  );

  return { heatmapSeries };
};

export default useHeatmapChart;
