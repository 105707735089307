import type { Metabolite } from "@/types/inspection/types";

/** 유사도 계산함수 */
export const calculateSimilarity = (
  patientValue: number,
  categoryValue: number
) => {
  const diff = Math.abs(patientValue - categoryValue);

  return (1 / (1 + diff * 2)) * 100;
};

export const parseMetaboliteToArray = (metaboliteObj: Metabolite) => {
  return Object.entries(metaboliteObj).map(([, value]) =>
    parseFloat(value as string)
  );
};
