import ShareIcon from "@/assets/share-icon.svg?react";

const ShareButton = () => {
  return (
    <button>
      <ShareIcon className="w-6 h-6 fill-gray-500 hover:fill-blue-400" />
    </button>
  );
};

export default ShareButton;
