import type { ApexOptions } from "apexcharts";

export const HEATMAP_COLOR_RANGE = [
  { from: 0, to: 10, color: "#0747a6" },
  { from: 10, to: 20, color: "#2165c0" },
  { from: 20, to: 30, color: "#3b84da" },
  { from: 30, to: 40, color: "#54a3f5" },
  { from: 40, to: 50, color: "#8abcf7" },
  { from: 50, to: 60, color: "#f7a6a6" },
  { from: 60, to: 70, color: "#f57373" },
  { from: 70, to: 80, color: "#f24040" },
  { from: 80, to: 90, color: "#e01e1e" },
  { from: 90, to: 100, color: "#c10000" },
];

export const METABOLITE_NAMES = [
  "lactic_acid",
  "styrene",
  "xylene1",
  "xylene2",
  "xylene3",
  "toluene",
  "1_propanol",
  "2_propanol",
  "phenol",
  "octane",
  "nonane",
  "decane",
  "undecane",
  "dodecane",
  "tridecane",
  "tetradecane",
  "hexadecane",
];

export const HEATMAP_OPTION: ApexOptions = {
  chart: {
    type: "heatmap",
    height: 500,
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    heatmap: {
      shadeIntensity: 0.3,
      radius: 0,
      useFillColorAsStroke: true,
      colorScale: {
        ranges: HEATMAP_COLOR_RANGE,
        inverse: true,
      },
    },
  },
  tooltip: {
    enabled: true,
    y: {
      formatter: (value) => `${value}%`,
    },
  },
  xaxis: {
    categories: ["정상", "양성", "악성"],
    position: "top",
    labels: {
      style: {
        fontSize: "16px",
        fontWeight: 500,
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        fontSize: "16px",
        fontWeight: 500,
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: false,
  },
};
