import { getInspectionTimeline } from "@/api/timeline/api";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

export const useGetTimelineData = ({
  token,
  inspectionId,
}: {
  token: string | null;
  inspectionId?: number;
}) => {
  const [progress, setProgress] = useState(0);
  const { data, isLoading } = useQuery({
    queryKey: ["timeline", inspectionId],
    queryFn: async () => {
      if (!token) throw new Error("토큰이 없습니다.");
      return getInspectionTimeline({
        token,
        inspectionId: inspectionId!,
        onProgress: setProgress,
      });
    },
    enabled: !!inspectionId && !!token,
  });

  return { data, isLoading, progress };
};
