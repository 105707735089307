import { apiClient, apiProgress } from "@/api/common/api";
import type {
  InspectionDashboardResponse,
  InspectionDetail,
  InspectionRequest,
} from "@/types/inspection/types";

export const getInspectionData = async ({
  page,
  token,
  onProgress,
}: InspectionRequest): Promise<InspectionDashboardResponse> => {
  try {
    const { data } = await apiClient.get<InspectionDashboardResponse>(
      `/inspection/dashboard?page=${page}`,
      {
        onDownloadProgress(progressEvent) {
          if (onProgress) apiProgress(progressEvent, onProgress);
        },
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    return data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getCompletedInspection = async ({
  page,
  token,
  search,
  type,
}: InspectionRequest): Promise<InspectionDashboardResponse> => {
  try {
    const { data } = await apiClient.get<InspectionDashboardResponse>(
      `/inspection/dashboard?page=${page}&searchValue=${search}&filterType=${type}`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    return data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getInspectionDetail = async ({
  token,
  inspectionId,
}: {
  token: string;
  inspectionId: number;
}) => {
  try {
    const { data } = await apiClient.get<InspectionDetail>(
      `/inspection/dashboard/${inspectionId}`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    return data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
