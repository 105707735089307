// constants/chartConstants.ts
import type { ApexOptions } from "apexcharts";

export const getBaseChartOptions = (
  resultDateArray: string[]
): Omit<ApexOptions, "series" | "annotations"> => ({
  chart: {
    type: "line",
    height: 350,
    width: 300,
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  xaxis: {
    categories: resultDateArray,
  },
  yaxis: [
    {
      title: {
        text: "가능성 (%)",
      },
      labels: {
        formatter: (val) => `${val}%`,
      },
    },
  ],
  stroke: {
    curve: "smooth",
  },
  colors: ["#66b266", "#ffcc66", "#ff6666", "#3366cc"],
  markers: {
    size: 4,
  },
  legend: {
    show: true,
    position: "right",
    offsetY: 20,
  },
  tooltip: {
    y: [
      {
        formatter: (val) => `${val}%`,
      },
      {
        formatter: (val) => `${val}%`,
      },
      {
        formatter: (val) => `${val}%`,
      },
    ],
  },
});

export const getAnnotationStyle = (
  date: string
): ApexOptions["annotations"] => ({
  xaxis: [
    {
      x: date,
      borderColor: "#FF4560",
      label: {
        borderWidth: 2,
        borderColor: "#FF4560",
        style: {
          color: "#fff",
          background: "#FF4560",
          fontSize: "14px",
          fontWeight: 700,
          padding: {
            left: 10,
            right: 10,
            top: 5,
            bottom: 5,
          },
        },
      },
    },
  ],
});
