import { searchAtom } from "@/store/inspection/atom";
import { useAtom } from "jotai";
import { debounce } from "lodash";
import { useCallback, type ChangeEvent } from "react";
import SearchIcon from "@/assets/search-icon.svg";

const SearchInput = ({
  setCurrentPage,
}: {
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [, setSearch] = useAtom(searchAtom);
  const debouncedSetSearch = useCallback(
    debounce((value: string) => {
      setSearch(value);
      setCurrentPage(0);
    }, 300),
    [setSearch]
  );

  const onChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    debouncedSetSearch(value);
  };

  return (
    <div className="flex items-center mr-[100px] border-gray-300 shadow-sm rounded-md border overflow-hidden">
      <input
        type="search"
        placeholder="검색할 반려동물의 이름을 입력해주세요!"
        className="w-[350px] p-2 pl-5 pr-5 text-sm border-none"
        onChange={(e) => onChangeValue(e)}
      />
      <button
        aria-label="검색 버튼"
        className="border-none p-2 pl-3 pr-3 h-full text-muted-foreground hover:text-foreground"
      >
        <img alt="검색이미지" src={SearchIcon} className="h-5 w-5" />
        <span className="sr-only">검색하기</span>
      </button>
    </div>
  );
};

export default SearchInput;
