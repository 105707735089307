import LineChart from "@/components/timeline/LineChart";
import { useStorage } from "@/hooks/common/useStorage";
import { useGetTimelineData } from "@/hooks/timeline/useGetTimelineData";
import { useState } from "react";
import HelpIcon from "@/assets/help_icon.svg?react";

interface TestTimelineProps {
  selectedId?: number;
}

const TestTimeline = ({ selectedId }: TestTimelineProps) => {
  const { accessToken } = useStorage();
  const { data, isLoading } = useGetTimelineData({
    token: accessToken as string,
    inspectionId: selectedId,
  });
  const [showTooltip, setShowTooltip] = useState(false);

  if (!selectedId) {
    return (
      <div className="bg-white shadow rounded-lg min-h-screen ml-5 p-6">
        <h2 className="text-xl font-semibold text-gray-900 mb-4">상세내용</h2>
        <p className="text-gray-500 text-center mt-10">
          검사 결과를 선택해주세요.
        </p>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="bg-white shadow rounded-lg  min-h-screen ml-5 p-6 flex justify-center items-center">
        로딩중입니다..
      </div>
    );
  }
  return (
    <div>
      {data && (
        <div className=" gap-2 mb-4 relative p-4">
          <div className="flex  justify-center items-center gap-2">
            <h3 className="text-xl font-semibold text-gray-900">타임라인</h3>
            <div
              className="relative"
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            >
              <HelpIcon className="w-5 h-5 fill-gray-400 cursor-help" />
              {showTooltip && (
                <div className="absolute z-50 left-6 top-0 w-[300px] p-6 text-sm bg-white border border-gray-200 rounded-lg shadow-lg">
                  타임라인은 시간에 따른 검사 결과 변화 추이를 확인할 수 있는
                  기능입니다. 정상, 양성 종양, 악성 종양 가능성의 변화를 쉽게
                  확인할 수 있으며, 이를 통하여 종양 진행 상태를 대략적으로
                  유추할 수 있습니다. 타임라인은 CancerVET을 최초로 검사를
                  수행하거나 칩 번호를 등록하지 않은 경우에는 제공되지 않습니다.
                </div>
              )}
            </div>
          </div>

          <LineChart data={data} />
        </div>
      )}
    </div>
  );
};

export default TestTimeline;
