import Footer from "@/components/common/Footer";
import LoginForm from "@/components/login/LoginForm";
import Banner from "@/components/test/main/Banner";
import Navigation from "@/components/test/main/Navigation";
import ProductCard from "@/components/test/main/ProductCard";

const TestMain = () => (
  <div className="min-h-screen bg-white">
    <Navigation />
    <div className="px-32 mt-12">
      <Banner />
      <main className="container mx-auto px-4 py-8">
        <div className="flex gap-8">
          <div className="w-1/2 bg-white shadow-lg rounded-lg p-8">
            <div className="text-center">
              <h1 className="mt-4 text-3xl font-bold text-gray-900">로그인</h1>
            </div>
            <LoginForm />
          </div>
          <div className="w-1/2 gap-8">
            <ProductCard />
            <ProductCard />
          </div>
        </div>
      </main>
    </div>
    <Footer />
  </div>
);

export default TestMain;
