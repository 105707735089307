import MailIcon from "@/assets/mail-icon.svg?react";

const MailButton = () => {
  return (
    <button>
      <MailIcon className="w-6 h-6 fill-gray-500 hover:fill-blue-400" />
    </button>
  );
};

export default MailButton;
