import { useEffect, useState, useRef } from "react";
import SearchInput from "@/components/inspection/SearchInput";
import SelectBox from "@/components/inspection/SelectBox";
import Pagination from "@/components/inspection/Pagination";
import useGetInspection from "@/hooks/inspection/useGetInspection";
import { useAtom, useAtomValue } from "jotai";
import { inspectionOptionAtom, searchAtom } from "@/store/inspection/atom";
import { LoadingPage } from "@/components/common/LoadingPage";
import { useStorage } from "@/hooks/common/useStorage";
import TestTable from "./TestTable";
import TestDetail from "./TestDetail";
import TestTimeline from "./TestTimeline";

const TestDashboard = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const inspectionOption = useAtomValue(inspectionOptionAtom);
  const { accessToken } = useStorage();
  const [search, setSearch] = useAtom(searchAtom);
  const [selectedId, setSelectedId] = useState<number>();
  const [, setIsDetailOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const dialogRef = useRef<HTMLDialogElement>(null);
  const rightPanelRef = useRef<HTMLDivElement>(null);

  const { data, isLoading, progress } = useGetInspection({
    page: currentPage,
    token: accessToken,
    type: inspectionOption,
    search,
  });

  useEffect(() => {
    setSearch("");
  }, []);

  useEffect(() => {
    if (rightPanelRef.current) {
      rightPanelRef.current.scrollTop = 0;
    }
  }, [selectedId]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1024);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (selectedId && isMobileView) {
      setIsDetailOpen(true);
      dialogRef.current?.showModal();
    }
  }, [selectedId, isMobileView]);

  const handleCloseDialog = () => {
    setIsDetailOpen(false);
    dialogRef.current?.close();
  };

  useEffect(() => {
    const dialog = dialogRef.current;
    if (dialog) {
      const handleClick = (e: MouseEvent) => {
        const rect = dialog.getBoundingClientRect();
        const isInDialog =
          rect.top <= e.clientY &&
          e.clientY <= rect.top + rect.height &&
          rect.left <= e.clientX &&
          e.clientX <= rect.left + rect.width;
        if (!isInDialog) {
          handleCloseDialog();
        }
      };

      dialog.addEventListener("click", handleClick);
      return () => dialog.removeEventListener("click", handleClick);
    }
  }, []);

  if (isLoading) return <LoadingPage progress={progress} />;

  return (
    <div className="h-[calc(100vh-4rem)] bg-gray-100">
      <main className="max-w-8xl mx-auto h-full p-4">
        <div className="flex gap-5 h-full">
          {/* Left Section - Always visible */}
          <div className="bg-white shadow rounded-lg p-6 w-full lg:w-3/5">
            <div className="flex items-center justify-around mb-6 flex-wrap gap-4">
              <div className="flex gap-4 flex-wrap">
                <SearchInput setCurrentPage={setCurrentPage} />
                <SelectBox setCurrentPage={setCurrentPage} />
              </div>
            </div>

            {data && (
              <div className="flex flex-col h-[calc(100%-4rem)]">
                <div className="flex-grow overflow-auto">
                  <TestTable
                    content={data.content}
                    totalElements={data.totalElements}
                    setSelectedId={setSelectedId}
                    selectedId={selectedId}
                  />
                </div>
                <div className="flex items-center justify-center mt-6">
                  <Pagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    dataLength={data.totalElements}
                  />
                </div>
              </div>
            )}
          </div>

          {/* Right Section - Only visible on desktop */}
          {!isMobileView && (
            <div className="hidden lg:flex bg-white shadow rounded-lg w-2/5 flex-col">
              <div ref={rightPanelRef} className="p-6 h-full overflow-y-auto">
                <TestDetail selectedId={selectedId} />
                <TestTimeline selectedId={selectedId} />
              </div>
            </div>
          )}
        </div>
      </main>

      {/* Modal for tablet/mobile view */}
      <dialog
        ref={dialogRef}
        className="w-full h-full p-0 bg-white fixed inset-0 z-50 backdrop:bg-black backdrop:bg-opacity-50"
      >
        <div className="flex flex-col h-full">
          <div className="flex justify-between items-center p-4 border-b">
            <h2 className="text-xl font-semibold">상세 내용</h2>
            <button
              onClick={handleCloseDialog}
              className="text-gray-500 hover:text-gray-700"
            >
              닫기
            </button>
          </div>
          {/* TODO : 타임라인이 칩번호가 존재하는 경우에만 표시할 수 있도록 수정요함. */}
          <div className="flex-1 overflow-y-auto p-4">
            <TestDetail selectedId={selectedId} />
            <TestTimeline selectedId={selectedId} />
          </div>
        </div>
      </dialog>
    </div>
  );
};

export default TestDashboard;
