const ProductCard = () => {
  return (
    <div className="bg-gray-100 p-8 rounded-lg mt-4">
      <h2 className="text-2xl font-bold mb-4">카탈로그</h2>
      <p className="text-gray-600 mb-6">다양한 제품 라인업을 확인하세요.</p>
      <button className="bg-gray-200 text-gray-700 px-6 py-2 rounded flex items-center space-x-2 hover:bg-gray-300">
        <span>다운로드</span>
        <svg
          className="w-4 h-4"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 14l-7 7m0 0l-7-7m7 7V3"
          />
        </svg>
      </button>
    </div>
  );
};

export default ProductCard;
