import { HEATMAP_COLOR_RANGE } from "@/constant/HEATMAP_CONSTANTS";

const ProgressBarChart = () => {
  const generateGradient = () => {
    const gradientStops = HEATMAP_COLOR_RANGE.map(
      (range) => `${range.color} ${range.to}%`
    ).join(", ");
    return `linear-gradient(to top, ${gradientStops})`;
  };

  return (
    <div className="flex flex-col gap-2 mt-3">
      <span className="text-sm text-gray-600 text-center">High</span>
      <div className="h-[590px] w-8">
        <div
          className="h-full w-full rounded-full"
          style={{
            background: generateGradient(),
          }}
        ></div>
      </div>
      <span className="text-sm text-gray-600 text-center">Low</span>
    </div>
  );
};

export default ProgressBarChart;
