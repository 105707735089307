import { keepPreviousData, useQuery } from "@tanstack/react-query";
import {
  getCompletedInspection,
  getInspectionData,
} from "@/api/inspection/api";
import type { InspectionRequest } from "@/types/inspection/types";
import { useState } from "react";

const useGetInspection = ({ page, token, type, search }: InspectionRequest) => {
  const [progress, setProgress] = useState(0);

  const { data, isLoading } = useQuery({
    queryKey: ["inspections", page, type, search],
    queryFn: async () => {
      if (!token) throw new Error("인증 토큰이 없습니다.");

      if (type === "전체 검사" && !search) {
        return getInspectionData({ page, token, onProgress: setProgress });
      } else if (type === "전체 검사") {
        return getCompletedInspection({ page, token, search, type: "ALL" });
      } else if (type === "검사 진행중") {
        return getCompletedInspection({
          page,
          token,
          search,
          type: "IN_PROGRESS",
        });
      } else if (type === "검사 완료") {
        return getCompletedInspection({ page, token, search, type: "DONE" });
      }
    },
    staleTime: 5000,
    retry: 1,
    placeholderData: keepPreviousData,
  });

  return { data, isLoading, progress };
};

export default useGetInspection;
