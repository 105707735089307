import type { Timeline } from "@/types/timeline/types";

export const TIMELINE_ARRAYS = (data: Timeline[]) => {
  const {
    normalArray,
    benignArray,
    malignantArray,
    weightArray,
    resultDateArray,
  } = data.reduce(
    (
      acc: {
        normalArray: number[];
        benignArray: number[];
        malignantArray: number[];
        weightArray: number[];
        resultDateArray: string[];
      },
      el
    ) => {
      acc.normalArray.push(Number(el.normal));
      acc.benignArray.push(Number(el.benign));
      acc.malignantArray.push(Number(el.malignant));
      acc.weightArray.push(Number(el.weight));
      acc.resultDateArray.push(el.requestDate.split("T")[0]);
      return acc;
    },
    {
      normalArray: [],
      benignArray: [],
      malignantArray: [],
      weightArray: [],
      resultDateArray: [],
    }
  );

  const chartSeries = [
    {
      name: "정상",
      data: normalArray,
      yAxisIndex: 0,
    },
    {
      name: "양성",
      data: benignArray,
      yAxisIndex: 0,
    },

    {
      name: "악성",
      data: malignantArray,
      yAxisIndex: 0,
    },
  ];

  return {
    chartSeries,
    normalArray,
    benignArray,
    malignantArray,
    weightArray,
    resultDateArray,
  };
};
