import { useForm } from "react-hook-form";
import type { LoginDTO } from "@/types/login/types";
import useAuth from "@/hooks/common/useAuth";
import AuthInput from "../common/AuthInput";

const LoginForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<LoginDTO>({
    mode: "onChange",
  });

  const { error, isPending, mutate } = useAuth({
    type: "accessToken",
  });

  if (isPending) {
    return (
      <div className="min-h-[300px] flex flex-col items-center justify-center space-y-4">
        <div className="w-12 h-12 border-4 border-blue-200 border-t-blue-600 rounded-full animate-spin"></div>
        <p className="text-gray-600 font-medium text-lg">로그인 중입니다...</p>
        <p className="text-gray-400 text-sm">잠시만 기다려주세요</p>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit((data) => mutate(data))} className="space-y-6">
      <AuthInput
        label="사업자 등록번호"
        id="loginId"
        type="text"
        register={register}
        placeholder={"사업자 등록번호"}
        isRequired={false}
        errors={errors.loginId}
        registerOptions={{
          required: "사업자 등록번호는 필수 항목입니다.",
          validate: {
            noHyphen: (value: string | FileList | null) =>
              (typeof value === "string" && !value.includes("-")) ||
              "사업자 등록번호에 하이픈(-)을 포함할 수 없습니다.",
            isTenDigits: (value: string | FileList | null) =>
              (typeof value === "string" && value.length === 10) ||
              "사업자 등록번호는 10자리여야 합니다.",
          },
        }}
      />
      <AuthInput
        label="비밀번호"
        id="password"
        type="password"
        register={register}
        placeholder={"비밀번호"}
        isRequired={false}
        errors={errors.password}
        registerOptions={{
          required: "비밀번호는 필수 항목입니다.",
          validate: {
            noHyphen: (value: string | FileList | null) =>
              (typeof value === "string" && !value.includes("-")) ||
              "비밀번호에 하이픈(-)을 포함할 수 없습니다.",
            isTenDigits: (value: string | FileList | null) =>
              (typeof value === "string" && value.length >= 10) ||
              "비밀번호는 10자리 이상이어야 합니다.",
          },
        }}
      />
      <button
        aria-label="로그인 버튼"
        type="submit"
        disabled={!isValid}
        className={`w-full p-2 rounded-lg ${
          isValid
            ? "bg-blue-600 hover:bg-blue-700 text-white"
            : "bg-gray-300 text-gray-500"
        }`}
      >
        로그인
      </button>
      {error && (
        <p className="text-red-500 text-sm mt-2">
          {error instanceof Error
            ? error.message
            : typeof error === "string"
            ? error
            : "로그인 중 오류가 발생했습니다."}
        </p>
      )}
    </form>
  );
};

export default LoginForm;
